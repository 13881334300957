body {
  margin: 0 !important;
  font-family: "Euclid Circular B", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  letter-spacing: -0.5px;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}
.overflow-y-hidden {
  overflow-y: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button:focus:not(:focus-visible) {
  outline: 0 !important;
}

.f-header {
  font-size: 48px;
  font-weight: 700;
}

.f-title {
  font-size: 48px;
  font-weight: 700;
}

.f-subtitle {
  font-size: 16px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.h-100 {
  height: 100%;
}

.margin-t-70 {
  margin-top: 70px;
}

.margin-b-70 {
  margin-bottom: 70px;
}

.padding-t-70 {
  padding-top: 70px;
}

.padding-b-70 {
  padding-bottom: 70px;
}

.margin-t-120 {
  margin-top: 120px;
}

.padding-t-120 {
  padding-top: 120px;
}

.padding-b-120 {
  padding-bottom: 120px;
}

@media (max-width: 576px) {
  .f-header {
    font-size: 40px;
  }
  .f-title {
    font-size: 40px;
  }
}
