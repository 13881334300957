.about-landing {
  background-color: #000014;
  height: 100vh;
  color: #fff;
}

.about-landing-text p {
  /* Neutral / N800 */

  color: #cacaca;
}

.something-colorful {
  transform: translateX(20px);
}

.landing-img {
  position: absolute;
}

.start-quotation {
  color: #283149;
  opacity: 0.1;
  font-size: 500px;
  transform: translate(-50px, -220px);
}

.about-roots {
  color: #000;
}

.triangle-1 {
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 45px solid #f3ecc8;
  transform: translate(150px, -100px) rotate(30deg);
  z-index: -1000;
}

.square-1 {
  height: 50px;
  width: 50px;
  background-color: #283149;
  right: 0;
  transform: translate(-200px, 100px);
  z-index: -1000;
}

.circle-1 {
  height: 30px;
  width: 30px;
  background-color: #f73859;
  border-radius: 50%;
  transform: translate(250px, 350px);
  z-index: -1000;
}

.circle-2 {
  height: 450px;
  width: 450px;
  border-radius: 50%;
  border: 5px solid #f1f2f5;
  transform: translate(20%, 300px);
  right: 0;
  z-index: -1000;
}

.circle-3 {
  height: 550px;
  width: 550px;
  background-color: #f1f2f5;
  border-radius: 50%;
  transform: translate(-40%, 550px);
  z-index: -1000;
  z-index: -1000;
}

.square-2 {
  height: 40px;
  width: 40px;
  background-color: #f3ecc8;
  right: 0;
  transform: translate(-50px, 800px) rotate(47deg);
  z-index: -1000;
}

.circle-4 {
  height: 30px;
  width: 30px;
  background-color: #f73859;
  border-radius: 50%;
  right: 0;
  transform: translate(-250px, 950px);
  z-index: -1000;
}

.square-3 {
  height: 18px;
  width: 18px;
  background-color: #404b69;
  transform: translate(50px, 1050px) rotate(-80deg);
  z-index: -1000;
}

.about-us-shapes {
  visibility: visible;
  display: block;
}

.layer-reveal-div {
  visibility: visible;
  display: block;
}

.layerMobile-reveal-div {
  visibility: hidden;
  display: none;
}

.about-core {
  color: #000;
}

.bold {
  font-weight: 800;
}
.semi-bold {
  font-weight: 600;
}

.core-card {
  background-color: #fff;
  border-radius: 40px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
  transition-property: all;
  transition: all 0.3s ease-in-out;
}

.core-card:hover {
  transform: scale(1.02);
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.08);
}

.core-icon {
  margin-top: 40px;
}
.core-icon img {
  height: 100%;
}

.core-title {
  margin-top: 30px;
}

.core-desc {
  margin-top: 40px;
  margin-bottom: 50px;
}

.core-row {
  margin-top: 75px;
}

.left-tag h4 {
  font-size: 14px !important;
}

.left-tag hr {
  border-color: #000;
}

.tag-dot {
  height: 15px;
  width: 15px;
  background-color: #000;
  border-radius: 50%;
  margin-top: -1.5rem !important;
}

.tag-1 {
  margin-top: -370px;
}

.tag-2 {
  margin-top: -170px;
}

.tag-3 {
  margin-top: -270px;
}

.tag-4 {
  margin-top: -70px;
}

.about-team {
  color: #000;
}

.team-img {
  height: 100%;
}

.team-name {
  font-size: 22px;
  font-weight: 600;
}

.team-title {
  font-size: 12px;
  font-weight: 500;
}

.about-ffp {
  color: #000;
}

.appendix {
  font-size: 10px;
  color: grey;
}

.minimalButton {
  background-color: rgba(255, 255, 255, 0.2) !important;
  border: none !important;
  color: rgb(230, 230, 230) !important;
}

.minimalButton:hover {
  background-color: rgba(255, 255, 255, 0.12) !important;
  border: none !important;
}

/* Responsive */

@media (max-width: 576px) {
  .something-colorful {
    transform: translateX(0px);
  }
  .about-landing-text {
    margin-top: -10rem;
  }

  .core-row {
    margin-top: 3rem;
  }
  .layer-reveal-div {
    visibility: hidden;
    display: none;
  }
  .layerMobile-reveal-div {
    visibility: visible;
    display: block;
  }
  .about-us-shapes {
    visibility: hidden;
    display: none;
  }
}

@media only screen and (max-width: 576px) and (max-height: 634px) {
  .about-landing-text {
    margin-top: 0rem !important;
  }
}
