:root {
  --nav-txt-color: #fff;
  --nav-logo-light-visibility: visible;
  --nav-logo-light-display: block;
  --nav-logo-dark-visibility: hidden;
  --nav-logo-dark-display: none;
  --nav-bg-color: rgba(0, 0, 0, 0.15);
}
[data-theme="light"] {
  --nav-txt-color: #000;
  --nav-logo-light-visibility: hidden;
  --nav-logo-light-display: none;
  --nav-logo-dark-visibility: visible;
  --nav-logo-dark-display: block;
  --nav-bg-color: rgba(0, 0, 0, 0.2);
}

.navbar-logo-light {
  visibility: var(--nav-logo-light-visibility);
  display: var(--nav-logo-light-display);
  opacity: 1;
  transition: opacity 0.3s ease;
}

.nav-brand {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */

  letter-spacing: -1.5px;

  color: #fff;
}

.navbar-logo-dark {
  visibility: var(--nav-logo-dark-visibility);
  display: var(--nav-logo-dark-display);
  opacity: 1;
  transition: opacity 0.3s ease;
}

.mynavbar {
  background-color: var(--nav-bg-color);
  width: 100vw;
  overflow-x: hidden !important;
  color: white;
}

.navbar-toggler {
  background-color: #7eb444;
  border: none !important;
}

.navbar-logo {
  height: 25px;
}

.navbar-logo-container:hover > .navbar-logo-light {
  opacity: 0.6 !important;
}

.navbar-logo-container:hover > .navbar-logo-dark {
  opacity: 0.6 !important;
}

.navbar-link-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.5px;

  color: var(--nav-txt-color) !important;
  margin-left: 3rem;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.navbar-link-text:hover {
  opacity: 0.6 !important;
}

@media (max-width: 576px) {
  .navbar-link-text {
    margin-left: 0rem !important;
  }
}
