.layer-reveal {
  height: 550px;
}

.layer-one {
  transform: scale(0.7);
}

.layer-two {
  transform: translateY(100px) scale(0.7);
}

.layer-three {
  transform: translateY(200px) scale(0.7);
}

.layer-four {
  transform: translateY(300px) scale(0.7);
}
