.layer-mobile-reveal {
  height: 550px;
}

.layer-mobile-one {
  transform: scale(0.45);
}

.layer-mobile-two {
  transform: translateY(100px) scale(0.45);
}

.layer-mobile-three {
  transform: translateY(200px) scale(0.45);
}

.layer-mobile-four {
  transform: translateY(300px) scale(0.45);
}
